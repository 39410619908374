import React, {Component} from 'react';
import FullCalendar from '@fullcalendar/react'
import timeGridPlugin from '@fullcalendar/timegrid'
import dayGridPlugin from '@fullcalendar/daygrid';
import locales_es, {fcLocale} from "../../locales/es";
import APIService from "../../modules/apiService";
import Helpers from "../../modules/helpers";
import Loading from "./../../components/loading"
import {
  APPOINTMENT_MIXED_TYPE,
  APPOINTMENT_PRESENTIAL_TYPE,
  APPOINTMENT_STATUS_OCCUPIED,
  APPOINTMENT_VIRTUAL_TYPE, CLINIC_PATIENTS_HANDLE_SHARE,
  DEFAULT_TIME_ZONE, FC_SLOT_DURATION,
  FC_SLOT_MAX_TIME,
  FC_SLOT_MIN_TIME,
  HREF_PAGE_ADD_PATIENT,
  HREF_PAGE_PATIENT,
  HREF_PAGE_VIDEOCALL,
  STATUS_COLORS,
  USER_TYPE_MEDIC,
  USER_TYPE_SECRETARY,
} from "../../models/constants";
import DateTimeService from "../../modules/DateTimeService";
import Modal from "../modal";
import Spinner from "../../components/spinner";
import VideoCallIcon from '../../images/video-call-16.png';
import Form from "../form";
import Schedules from "../schedules";
import StatusDropdown from "../statusDropdown";
// import moment from 'moment-timezone';

import "./index.css";
import PaymentStatusDropdown from "../paymentStatusDropdown";
import ConfigService from "../../modules/configService";
import CalendarView from "../../modules/CalendarView";
import AuthService from "../../modules/authService";
import {DateTime} from "luxon";
import TimePicker from "../TimePicker";
import {getStoredTime} from '../../utils/timeUtils';
import ViewHelpers from "../../modules/viewHelpers";
import ContactButtons from "../ContactButtons";

export default class AppointmentsCalendar extends Component {

  constructor(props) {
    super(props);

    const defaultStartDate = new Date();
    defaultStartDate.setMinutes(0);
    defaultStartDate.setHours(defaultStartDate.getHours() + 1);

    const defaultStartTime = getStoredTime('slotMinTime', FC_SLOT_MIN_TIME, true);
    const defaultEndTime = getStoredTime('slotMaxTime', FC_SLOT_MAX_TIME, true);
    const defaultSlotDuration = localStorage.getItem('slotDuration') || FC_SLOT_DURATION;

    this.api = new APIService();
    this.helpers = new Helpers();
    this.auth = new AuthService();
    this.dateTimeService = new DateTimeService();
    this.calendarComponentRef = React.createRef();
    this.timePickerRef = React.createRef();
    this.configService = new ConfigService();
    this.calendarView = new CalendarView();
    this.viewHelpers = new ViewHelpers();
    this.retryCount = 0;
    this.maxRetries = 3; // Número máximo de reintentos

    this.state = {
      loading: true,
      appointmentId: window.URLSearchParams
        ? JSON.parse(new window.URLSearchParams(this.props.location.search).get("appointmentId")) : null,
      slot: window.URLSearchParams
        ? JSON.parse(new window.URLSearchParams(this.props.location.search).get("slot")) : null,
      patientDestination: 'me',
      owner_str: '',
      initialView: this.calendarView.get(), // timeGridWeek, dayGridWeek, dayGridMonth
      appointmentTypes: [{
        id: APPOINTMENT_PRESENTIAL_TYPE
      }],
      appointmentTypeId: null,
      externalVideocallUrl: null,
      overbookingModal: false,
      duration: 30,
      startDate: defaultStartDate,
      timezone: this.props.medic.time_zone ?? DEFAULT_TIME_ZONE,
      slotMinTime: defaultStartTime,
      slotMaxTime: defaultEndTime,
      slotDuration: defaultSlotDuration,
      otherPatientsSearch: false
    };
  }

  componentDidMount() {
    if (this.props.medic && this.props.medic.id) {
      this.api.getMedicAppointmentsTypes(this.props.medic.id).then(res => {
        this.setState({
          appointmentTypes: res.data,
          appointmentTypeId: res.data && res.data.length ? res.data[0].id : ''
        })
      }).catch(err => {
        this.props.showMainModal(locales_es.errorModal.title, this.helpers.getErrorMsg(err));
      });

      this.getConsultingRoomOptions();
      this.getIdentificationTypes();
      this.getAppointmentId();
    }
  }

  getAppointmentId() {
    if (this.state.appointmentId) {
      this.api.getAppointment(this.state.appointmentId).then(res => {
        if (res && res.data) {
          const obj = res.data;
          obj.appointment_id = res.data.id;
          obj.startStr = res.data.start;
          obj.endStr = res.data.end;
          this.onEventClick(obj);
        }
      }).catch(err => {
        this.props.showMainModal(locales_es.errorModal.title, this.helpers.getErrorMsg(err));
      });
    }
  }

  getConsultingRoomOptions() {
    this.configService.getLocalClinicData().then(clinic => {
      this.api.getConsultingRooms({clinic_id: clinic.id}).then(res => {
        this.setState({
          clinicId: clinic.id,
          consultingRoomOptions: res.data
        }, () => {
          this.loadMedicClinics();
        });
      }).catch(err => {
        this.props.showMainModal(locales_es.errorModal.title, this.helpers.getErrorMsg(err));
        this.setLoading(false);
      });
    }).catch(err => {
      console.log(err);
    });
  }

  getIdentificationTypes() {
    this.api.getIdentificationTypes().then(res => {
      this.setState({
        identificationOptions: res.data
      })
    }).catch(err => {
      this.props.showMainModal(locales_es.errorModal.title, this.helpers.getErrorMsg(err));
    });
  }

  setLoading(bool) {
    this.setState({
      loading: bool
    })
  }


  parseEventsForFullCalendar(events) {
    return events.map(event => {
      // Id del slot
      event.timetable_slot_id = event.id;
      // const consultingName = this.state.consultingRoomOptions.filter(room => room.id == event.consulting_room_id)[0].name
      event.className = ['medicloud-fc-event'];

      // Copiamos el start para el modal de asignación de turno
      event.startStr = event.start;
      event.endStr = event.end;

      // Timezone definido para turnos físicos // Esto hay que apagarlo en videollamadas
      const startDate = DateTime.fromISO(event.start, {zone: (this.state.timezone ?? DEFAULT_TIME_ZONE)});
      const endDate = DateTime.fromISO(event.end, {zone: (this.state.timezone ?? DEFAULT_TIME_ZONE)});

      event.start = startDate.toISO();
      event.end = endDate.toISO();

      if (event.patient_id) {
        event.title = `${event.patient?.name ?? ''} ${event.patient?.lastname ?? ''}`;
        event.className.push('medicloud-remove-cursor-icon');
        event.notes = event.patient?.notes;
      } else {
        event.title = `${locales_es.freeAppointment}`;
        event.className.push('medicloud-fc-event-pointer');
      }

      if (event.status) {
        event.className.push(`fc-dot ${STATUS_COLORS.calendarDot[event.status]}`);
        event.className.push(`medicloud-event-status-${event.status}`);
      }

      if (event.owner_str) {
        event.title += ` - ${event.owner_str}`;
      }
      if (event.slot_status === APPOINTMENT_STATUS_OCCUPIED || !event.timetable_id) {
        event.className.push('medicloud-fc-event--occupied');

        if (!event.patient_id) {
          event.title = locales_es.blockedAppointment;
          event.className.push('medicloud-fc-event--blocked');
        }
      }

      if (Number(event.type_id) === Number(APPOINTMENT_VIRTUAL_TYPE)) {
        event.className.push('medicloud-event--videocall');
        event.title += ` (Videollamada)`;
      }

      if (Number(event.type_id) === Number(APPOINTMENT_MIXED_TYPE)) {
        event.className.push('medicloud-event--mixed');
        event.title += ` (MIXTO) +Info`;
      }

      // Eliminar campos no necesarios
      delete event.duration; // BORRADO PORQUE ENTRA EN CONFLICTO CON FULLCALENDAR. RENOMBRADO COMO consultation_duration
      delete event.created_at;
      delete event.updated_at;
      delete event.deleted_at;
      return event;
    });
  }

  parseNonWorkingEvent(events) {
    return events.map(event => {
      // const consultingName = this.state.consultingRoomOptions.filter(room => room.id == event.consulting_room_id)[0].name
      event.className = ['medicloud-fc-event'];

      // Timezone definido para turnos físicos // Esto hay que apagarlo en videollamadas
      const eventDate = DateTime.fromISO(event.date, {zone: (this.state.timezone ?? 'local')});
      event.start = eventDate.startOf('day').toISO();
      event.end = eventDate.endOf('day').toISO();

      event.title = locales_es.nonWorkingDays;
      event.type = 'non-working';
      event.display = 'background';

      // Eliminar campos no necesarios
      delete event.duration; // BORRADO PORQUE ENTRA EN CONFLICTO CON FULLCALENDAR. RENOMBRADO COMO consultation_duration
      delete event.created_at;
      delete event.updated_at;
      delete event.deleted_at;

      return event;
    });
  }

  getCalendarData(fetchInfo, successCallback) {
    try {
      const objData = {
        medic_id: this.props.medic.id,
        clinic_id: this.state.clinicId,
        start: new Date(fetchInfo.start).toISOString(),
        end: new Date(fetchInfo.end).toISOString(),
      };
      this.api.getNonWorkingDays(objData).then(nonWorkingRes => {

        const parsedNonWorkingRes = this.parseNonWorkingEvent(nonWorkingRes.data);

        this.api.getAppointments(objData).then(res => {
          // this.getSlotMinMaxTime(res.data);
          successCallback(
            this.parseEventsForFullCalendar(res.data).concat(parsedNonWorkingRes)
          );

        }).catch(err => {
          this.retryCount = this.retryCount + 1;
          this.props.showMainModal(locales_es.errorModal.title, this.helpers.getErrorMsg(err));
        });

      }).catch(err => {
        this.props.showMainModal(locales_es.errorModal.title, this.helpers.getErrorMsg(err));
      });
    } catch (error) {
      console.log(error);
    }
  }

  onEventClick(event) {
    if (event.type === "non-working") {
      // Es una día no laboral, no hacemos nada
      return;
    }
    if (event.slot_status === APPOINTMENT_STATUS_OCCUPIED && !event.patient_id) {
      // Es un turno bloqueado; preguntamos si procedemos a desbloquearlo.
      const confirm = window.confirm(locales_es.confirmAppointmentUnlock);
      if (confirm) {
        this.api.cancelAppointment(event.appointment_id).then(res => {
          this.props.showMainModal(locales_es.successModal.title, res.message);
          this.componentDidMount();
        }).catch(err => {
          this.props.showMainModal(locales_es.errorModal.title, this.helpers.getErrorMsg(err));
        })
      }
      return;
    }
    this.setState({
      appointmentTypeId: Number(event.type_id) === APPOINTMENT_MIXED_TYPE || this.state.overbookingModal ? this.state.appointmentTypes[0].id : event.type_id
    }, () => {
      const initialView = this.calendarComponentRef.current._calendarApi.currentDataManager.state.currentViewType;
      if (event.appointment_id) {
        const clonedEvent = JSON.parse(JSON.stringify(event));
        clonedEvent.info = true;
        this.api.getAppointment(event.appointment_id).then(res => {

          const mergedEvent = Object.assign(clonedEvent, res.data);

          this.setState({
            slot: mergedEvent,
            patient: mergedEvent.patient,
            appointmentTypeId: res.data.type_id,
            externalVideocallUrl: res.data.external_videocall_url,
            initialDate: this.calendarComponentRef.current._calendarApi.getDate(),
            initialView,
          });
        }).catch(err => {
          this.props.showMainModal(locales_es.errorModal.title, this.helpers.getErrorMsg(err));
        });
      } else {
        if (this.state.overbookingModal) {
          this.setState({
            slot: event
          })
        } else {
          if (event.timetable_id) {
            this.api.getTimetablePrices(event.timetable_id).then(res => {
              const prices = res.data.map(p => {
                p.name = `${p.currency_id || locales_es.currency_sign} ${p.price} - ${p.title}`;
                return p;
              });
              this.setState({
                slot: event,
                priceId: prices.length ? prices[0].id : null,
                prices,
                initialDate: this.calendarComponentRef.current._calendarApi.getDate(),
                initialView,
              });
            }).catch(err => {
              this.props.showMainModal(locales_es.errorModal.title, this.helpers.getErrorMsg(err));
            });
          } else {
            this.setState({
              slot: event,
              priceId: null,
              initialDate: this.calendarComponentRef.current._calendarApi.getDate(),
              initialView,
            });
          }
        }
      }
    });
  }

  deleteAppointment() {
    const confirm = window.confirm(locales_es.confirmAppointmentRemoval);
    if (confirm) {
      this.api.cancelAppointment(this.state.slot.appointment_id).then(res => {
        this.props.showMainModal(locales_es.successModal.title, res.message);
        this.componentDidMount();
      }).catch(err => {
        this.props.showMainModal(locales_es.errorModal.title, this.helpers.getErrorMsg(err));
      })
    }
    this.closeModal();
  }

  // Buscador de pacientes
  onSearchSubmit(ev) {
    if (ev && ev.preventDefault) {
      ev.preventDefault();
    }
    const form = document.getElementById('searchForm');
    const query = form.children[0].value;

    this.setState({
      query,
      patients: null,
      otherPatientsSearch: false,
    }, () => {
      if (!query) {
        this.setState({
          patients: undefined
        });
        return;
      }

      setTimeout(() => {
        if (this.state.query.length === query.length) {
          const objData = {q: query};
          let promise;
          this.configService.getLocalClinicData().then(clinic => {
            if (clinic.patients_handle === CLINIC_PATIENTS_HANDLE_SHARE) {
              promise = this.api.searchPersons;
              objData.clinic_id = clinic.id;
            } else {
              if (this.auth.getLocalUserType() === USER_TYPE_SECRETARY) {
                objData.medic_id = this.props.medic?.id ?? null;
                promise = this.api.searchPatients;
              } else {
                promise = this.api.searchPatients;
              }
            }
            promise(objData).then(res => {
              this.setState({
                patients: res.data,
              });
              if (clinic.patients_handle !== CLINIC_PATIENTS_HANDLE_SHARE && this.auth.getLocalUserType() === USER_TYPE_SECRETARY && res.data.length === 0) {
                this.searchOtherPatients(objData);
              }
            }).catch(err => {
              this.props.showMainModal(locales_es.errorModal.title, this.helpers.getErrorMsg(err));
            })
          }).catch(err => {
            console.log(err);
          });
        }
      }, 500); // 800
    })
  }

  searchOtherPatients(objData) {
    this.setState({
      otherPatientsSearch: true,
      patients: null,
    }, () => {
      delete objData.medic_id;
      this.api.searchPatients(objData).then(res => {
        this.setState({
          patients: res.data,
        });
      }).catch(err => {
        this.props.showMainModal(locales_es.errorModal.title, this.helpers.getErrorMsg(err));
      })
    });
  }

  onSearchClear(ev) {
    if (ev && ev.preventDefault) {
      ev.preventDefault();
    }
    const form = document.getElementById('searchForm');
    form.children[0].value = '';

    this.setState({
      query: '',
      patients: undefined
    })
  }

  validateForm() {
    if (!this.state.patient) {
      this.props.showMainModal(locales_es.errorModal.title, locales_es.errorModal.selectAPatient);
      return false;
    }

    if (!this.state.appointmentTypeId || this.state.appointmentTypeId === APPOINTMENT_MIXED_TYPE) {
      this.props.showMainModal(locales_es.errorModal.title, locales_es.errorModal.selectAnAppointmentType);
      return false;
    }

    return true;
  }

  postAppointment() {
    if (this.validateForm()) {
      const objData = JSON.parse(JSON.stringify(this.state.slot));
      objData.patient_id = this.state.patient.id;
      objData.start = this.state.slot.startStr;
      objData.end = this.state.slot.endStr;
      objData.type_id = this.state.appointmentTypeId;
      objData.external_videocall_url = this.state.externalVideocallUrl;
      if (this.state.owner_str) {
        objData.owner_str = this.state.owner_str
      }
      if (this.state.priceId) {
        objData.timetable_price_id = this.state.priceId
      }
      objData.timetable_slot_id = this.state.slot.timetable_slot_id;

      this.configService.getLocalClinicData().then(clinic => {
        // si esto es true, el id que tengo es un personId. Necesito buscar el patientId
        if (clinic.patients_handle === CLINIC_PATIENTS_HANDLE_SHARE) {
          this.api.getPatientsByMedicAndPerson(this.props.medic.id, this.state.patient.id).then(res => {
            objData.patient_id = res.data.id;
            this.api.postAppointment(objData).then(res => {
              this.props.showMainModal(locales_es.successModal.title, res.message);
              this.removePatient();
              this.setState({
                slot: null
              })
            }).catch(err => {
              this.props.showMainModal(locales_es.errorModal.title, this.helpers.getErrorMsg(err));
            });
          }).catch(err => {
            this.props.showMainModal(locales_es.errorModal.title, this.helpers.getErrorMsg(err));
          });
        } else {
          if (Number(this.state.patient.medic_id) === Number(this.props.medic.id)) {
            this.api.postAppointment(objData).then(res => {
              this.props.showMainModal(locales_es.successModal.title, res.message);
              this.removePatient();
              this.setState({
                slot: null
              })
            }).catch(err => {
              this.props.showMainModal(locales_es.errorModal.title, this.helpers.getErrorMsg(err));
            });
          } else {
            this.api.postLinkPatientToMedic({
              medic_id: this.props.medic.id,
              patient_id: this.state.patient.id
            }).then(res => {
              objData.patient_id = res.data?.id;
              this.api.postAppointment(objData).then(res => {
                this.props.showMainModal(locales_es.successModal.title, res.message);
                this.removePatient();
                this.setState({
                  slot: null
                })
              }).catch(err => {
                this.props.showMainModal(locales_es.errorModal.title, this.helpers.getErrorMsg(err));
              });
            }).catch(err => {
              this.props.showMainModal(locales_es.errorModal.title, this.helpers.getErrorMsg(err));
            });
          }
        }
      }).catch(err => {
        console.log(err)
      });
    }
  }

  postOverbookingAppointment() {
    if (this.validateForm()) {
      const objData = JSON.parse(JSON.stringify(this.state.slot));
      objData.patient_id = this.state.patient.id;
      objData.start = this.state.startDate.toISOString();

      const endDate = new Date(this.state.startDate);

      const duration = Number(this.state.duration);

      endDate.setMinutes(endDate.getMinutes() + (duration > 0 ? duration : 30));
      objData.end = endDate.toISOString();

      objData.type_id = this.state.appointmentTypeId;
      if (this.state.clinics && this.state.clinics.length) {
        objData.clinic_id = this.state.clinics && this.state.clinics.length ? this.state.clinics[0].id : null;
      } else if (this.state.clinicId) {
        objData.clinic_id = this.state.clinicId;
      } else {
        alert('Tienes que dar de alta un lugar de atención y al menos una franja horaria de atención para poder brindar sobreturnos');
        return;
      }
      objData.time_zone = this.state.timezone;
      if (this.state.owner_str) {
        objData.owner_str = this.state.owner_str
      }

      // CHEQUEO QUE ESTE PACIENTE LE PERTENEZCA AL MÉDICO, Y SINO LO VINCULO
      this.configService.getLocalClinicData().then(clinic => {
        // si esto es true, el id que tengo es un personId. Necesito buscar el patientId
        if (clinic.patients_handle === CLINIC_PATIENTS_HANDLE_SHARE) {
          this.api.getPatientsByMedicAndPerson(this.props.medic.id, this.state.patient.id).then(res => {
            objData.patient_id = res.data.id;
            this.api.postAppointment(objData).then(res => {
              this.props.showMainModal(locales_es.successModal.title, res.message);
              this.removePatient();
              this.setState({
                slot: null,
                overbookingModal: false,
              })
            }).catch(err => {
              this.props.showMainModal(locales_es.errorModal.title, this.helpers.getErrorMsg(err));
            });
          }).catch(err => {
            this.props.showMainModal(locales_es.errorModal.title, this.helpers.getErrorMsg(err));
          });
        } else {
          if (Number(this.state.patient.medic_id) === Number(this.props.medic.id)) {
            this.api.postAppointment(objData).then(res => {
              this.props.showMainModal(locales_es.successModal.title, res.message);
              this.removePatient();
              this.setState({
                slot: null,
                overbookingModal: false,
              })
            }).catch(err => {
              this.props.showMainModal(locales_es.errorModal.title, this.helpers.getErrorMsg(err));
            });
          } else {
            this.api.postLinkPatientToMedic({
              medic_id: this.props.medic.id,
              patient_id: this.state.patient.id
            }).then(res => {
              objData.patient_id = res.data?.id;
              this.api.postAppointment(objData).then(res => {
                this.props.showMainModal(locales_es.successModal.title, res.message);
                this.removePatient();
                this.setState({
                  slot: null,
                  overbookingModal: false,
                })
              }).catch(err => {
                this.props.showMainModal(locales_es.errorModal.title, this.helpers.getErrorMsg(err));
              });
            }).catch(err => {
              this.props.showMainModal(locales_es.errorModal.title, this.helpers.getErrorMsg(err));
            });
          }
        }
      }).catch(err => {
        console.log(err)
      });
    }
  }

  blockAppointment() {
    const objData = JSON.parse(JSON.stringify(this.state.slot));
    objData.start = this.state.slot.startStr;
    objData.end = this.state.slot.endStr;
    objData.timetable_slot_id = this.state.slot.timetable_slot_id;
    this.api.postBlockAppointment(objData).then(res => {
      this.props.showMainModal(locales_es.successModal.title, res.message);
      this.removePatient();
      this.setState({
        slot: null
      })
    }).catch(err => {
      this.props.showMainModal(locales_es.errorModal.title, this.helpers.getErrorMsg(err));
    });
  }

  selectPatient(patient) {
    this.setState({
      patient,
    })
  }

  removePatient() {
    this.setState({
      patient: null,
      patients: undefined
    })
  }

  renderAddPatientButton(isOverbookingModal) {
    let url = `${HREF_PAGE_ADD_PATIENT}?redirect=${window.location.pathname}`;
    if (!isOverbookingModal) {
      url += `&slot=${JSON.stringify(this.state.slot)}&medic_id=${this.props.medic.id}`;
    }
    return (
      <div className="row">
        <div className="pt-3 m-auto">
          <a
            href={url}
            className="btn btn-brand btn-sm btn-bold btn-upper">{locales_es.addPatient}</a>
        </div>
      </div>
    )
  }

  onValueChange(event) {
    this.setState({
      owner_str: '',
      patientDestination: event.target.value
    });
  }

  handleChange(ev) {
    this.setState({owner_str: ev.target.value});
  }

  handleFormChange = state => ev => {
    this.setState({[state]: ev.target.value});
  };

  handleDateChange = state => value => {
    this.setState({[state]: value});
  };

  closeModal() {
    this.setState({
      slot: null,
      patients: undefined,
      patient: null,
      externalVideocallUrl: null,
      overbookingModal: false,
    })
  }

  putAppointment() {
    const objData = {
      external_videocall_url: this.state.externalVideocallUrl,
      type_id: this.state.appointmentTypeId,
    };
    this.api.putAppointment(this.state.slot.appointment_id, objData).then(res => {
      this.props.showMainModal(locales_es.successModal.title, res.message);
      this.closeModal();
    }).catch(err => {
      this.props.showMainModal(locales_es.errorModal.title, this.helpers.getErrorMsg(err));
      this.closeModal();
    });
  }

  getVideocallUrl(slot) {
    if (slot.external_videocall_url) {
      return slot.external_videocall_url;
    }
    return `${HREF_PAGE_VIDEOCALL}/${slot.videocall_hash}`;
  }

  showOverbookingModal() {
    this.setState({
      overbookingModal: true
    }, () => {
      const eventObj = {
        "medic_id": this.props.medic.id,
        "timetable_id": null,
      };
      this.onEventClick(eventObj);
    });
  }

  loadMedicClinics() {
    // this.api.getMyClinics().then(res => {
    this.api.getClinicsByUser(this.props.medic.id).then(res => {
      this.setState({
        clinics: res.data
      }, () => {
        if (this.auth.getLocalUserType() === USER_TYPE_MEDIC) {
          this.setMedicDefaults();
        }
        if (this.auth.getLocalUserType() === USER_TYPE_SECRETARY) {
          this.setSecretaryDefaults();
        }
      });
    }).catch(err => {
      this.props.showMainModal(locales_es.errorModal.title, this.helpers.getErrorMsg(err));
    })
  }

  setMedicDefaults() {
    this.setState({
      consulting_room_id: this.state.consultingRoomOptions && this.state.consultingRoomOptions.length ? this.state.consultingRoomOptions[0].id : null,
    }, () => {
      this.setLoading(false);
    });
  }

  setSecretaryDefaults() {
    this.configService.getLocalClinicData().then(clinic => {
      this.setState({
        // timezone: this.auth.getUserData().user.time_zone,
        clinicId: clinic.id,
        // clinicId: this.state.clinics && this.state.clinics.length ? this.state.clinics[0].id : null, // TODO clinics/me de una secretary no devuelve nada
        consulting_room_id: this.state.consultingRoomOptions && this.state.consultingRoomOptions.length ? this.state.consultingRoomOptions[0].id : null,
      }, () => {
        this.setLoading(false);
      });
    }).catch(err => {
      console.log(err);
      this.setLoading(false);
    })
  }

  onClickAppointmentBell(appointment_id, consulting_room_id) {
    this.api.postAppointmentWaitingRoom({appointment_id, consulting_room_id}).then((() => {
      this.props.showMainModal(locales_es.successModal.title, 'Se llamó al paciente a través del llamador de la sala de espera');
    })).catch(err => {
      this.props.showMainModal(locales_es.errorModal.title, this.helpers.getErrorMsg(err));
    });
  }

  getSlotMinMaxTime = (events) => {
    if (!events || events.length === 0) {
      return {slotMinTime: '00:00', slotMaxTime: '23:59'};
    }

    const timezone = this.state.timezone ?? DEFAULT_TIME_ZONE;

    let minTime = DateTime.fromISO(events[0].start, {zone: 'utc'}).setZone(timezone);
    let maxTime = DateTime.fromISO(events[0].end, {zone: 'utc'}).setZone(timezone);

    events.forEach(event => {
      const startTime = DateTime.fromISO(event.start, {zone: 'utc'}).setZone(timezone);
      const endTime = DateTime.fromISO(event.end, {zone: 'utc'}).setZone(timezone);

      // Crear solo horas y minutos para comparación
      const startHourMinute = startTime.toFormat('HH:mm');
      const endHourMinute = endTime.toFormat('HH:mm');

      if (startHourMinute < minTime.toFormat('HH:mm')) {
        minTime = startTime;
      }
      if (endHourMinute > maxTime.toFormat('HH:mm')) {
        maxTime = endTime;
      }
    });

    // Convertir a "HH:MM"
    const slotMinTime = minTime.toFormat('HH:mm');
    const slotMaxTime = maxTime.toFormat('HH:mm');

    // Puedes devolver los valores o establecer el estado según tus necesidades
    return {slotMinTime, slotMaxTime};
  };

  clearTimes = () => {
    if (this.timePickerRef.current) {
      this.timePickerRef.current.clearStoredTimes();
    }
  };

  onClickPatientCard = (patient) => {// CHEQUEO QUE ESTE PACIENTE LE PERTENEZCA AL MÉDICO, Y SINO LO VINCULO
    this.configService.getLocalClinicData().then(clinic => {
      // si esto es true, el id que tengo es un personId. Necesito buscar el patientId
      if (clinic.patients_handle === CLINIC_PATIENTS_HANDLE_SHARE && !this.state.slot?.appointment_id) {
        this.api.getPatientsByMedicAndPerson(this.props.medic.id, patient.id).then(res => {
          window.open(`${HREF_PAGE_PATIENT}/${res.data.id}`);
        }).catch(err => {
          this.props.showMainModal(locales_es.errorModal.title, this.helpers.getErrorMsg(err));
        });
      } else {
        window.open(`${HREF_PAGE_PATIENT}/${patient.id}`);
      }
    }).catch(err => {
      console.log(err)
    });
  }

  render() {
    const {loading, slot, patients, patient, prices, query, overbookingModal, otherPatientsSearch} = this.state;

    const inputs = [
      {
        label: locales_es.appointmentType,
        placeholder: locales_es.appointmentType,
        id: 1,
        state: 'appointmentTypeId',
        value: this.state.appointmentTypeId,
        type: 'select',
        required: true,
        options: this.state.appointmentTypes,
        wrapperCustomClassName: 'form-group',
        // disabled: slot && slot.type_id !== APPOINTMENT_MIXED_TYPE,
      },
    ];

    const priceInputs = [
      {
        label: locales_es.price,
        placeholder: locales_es.price,
        id: 2,
        state: 'priceId',
        value: this.state.priceId,
        type: 'select',
        required: true,
        options: this.state.prices,
        wrapperCustomClassName: 'form-group',
        // disabled: slot && slot.type_id !== APPOINTMENT_MIXED_TYPE,
      },
    ];

    const videocallForm = [
      {
        label: locales_es.externalVideocallLink,
        placeholder: locales_es.externalVideocallLinkPlaceholder,
        id: 3,
        state: 'externalVideocallUrl',
        value: this.state.externalVideocallUrl,
        type: 'text',
        required: false,
        wrapperCustomClassName: 'form-group',
        // disabled: slot && slot.type_id !== APPOINTMENT_MIXED_TYPE,
      },
    ];

    // Obtener la hora actual
    let now = new window.Date();
    now.setMinutes(0);
    now.setHours(now.getHours + 1);


    const overbookingModalDatesInput = [
      {
        label: locales_es.startDate,
        placeholder: locales_es.startDate,
        id: 4,
        state: 'startDate',
        value: this.state.startDate,
        type: 'datetime',
        required: true,
        wrapperCustomClassName: 'form-group',
        minDate: now,
      },
      {
        label: `${locales_es.appointmentDuration} (${locales_es.inMinutes})`,
        placeholder: `(${locales_es.inMinutes})`,
        id: 5,
        state: 'duration',
        value: this.state.duration,
        type: 'number',
        step: 1,
        min: 1,
        max: 60,
        required: true,
        wrapperCustomClassName: 'form-group col-md-6 pl-0',
      },
    ];

    if (this.auth.getLocalUserType() === USER_TYPE_MEDIC) {
      overbookingModalDatesInput.push({
        label: locales_es.consultingPlace,
        placeholder: locales_es.consultingPlace,
        id: 6,
        state: 'clinicId',
        value: this.state.clinicId,
        type: 'select',
        required: true,
        options: this.state.clinics,
        wrapperCustomClassName: 'form-group',
      })
    }

    const handleDatesSet = (arg) => {
      if (arg.view.type !== this.state.initialView) {
        console.log("La vista ha cambiado de", this.state.initialView, "a", arg.view.type);
        this.calendarView.set(arg.view.type);
      }
    };

    return (
      <>
        {
          loading ?
            <Loading/>
            :
            slot ?
              <Modal modalId="assignAppointment"
                     title={!slot.info ? (overbookingModal ? locales_es.assignExtraAppointment : locales_es.assignAppointment) : locales_es.appointmentInfo}
                     visible={slot}
                     hideCloseButton={true}
                     actions={!slot.info ? [
                       {
                         className: overbookingModal ? 'd-none' : patient ? 'btn btn-brand btn-danger btn-pill m-3 align-self-start d-none' : 'btn btn-brand btn-danger btn-pill m-3 align-self-start',
                         title: locales_es.blockAppointment,
                         onClick: () => this.blockAppointment()
                       },
                       {
                         className: 'btn btn-brand btn-elevate btn-pill m-3 align-self-start',
                         title: overbookingModal ? locales_es.assignExtraAppointment : locales_es.assignAppointment,
                         onClick: () => overbookingModal ? this.postOverbookingAppointment() : this.postAppointment()
                       },
                       {
                         className: 'btn btn-secondary btn-pill',
                         title: locales_es.cancel,
                         onClick: () => this.closeModal()
                       }
                     ] : [
                       {
                         className: 'btn btn-brand btn-warning btn-pill m-3 align-self-start',
                         title: locales_es.saveChanges,
                         onClick: () => this.putAppointment()
                       },
                       {
                         className: 'btn btn-brand btn-danger btn-pill m-3 align-self-start',
                         title: locales_es.remove,
                         onClick: () => this.deleteAppointment()
                       },
                       {
                         className: 'btn btn-secondary btn-pill',
                         title: locales_es.close,
                         onClick: () => this.closeModal()
                       }
                     ]}
              >
                <div className="kt-section mt-3">
                  <div className="kt-section__desc">
                    {slot.appointment_id &&
                      <>
                        {this.state.clinicId ?
                          slot.timetable_id ?
                            <button onClick={() => this.onClickAppointmentBell(slot.appointment_id)} type="button"
                                    className="btn btn-outline-accent btn-elevate btn-pill woopi-appointment-bell"><i
                              class="flaticon-bell pr-0 pr-md-2"></i><span
                              className="d-none d-md-inline">{locales_es.announcePatient}</span></button>
                            : <div className="btn-group woopi-appointment-bell">
                              <button type="button"
                                      className="btn btn-outline-accent btn-elevate btn-pill dropdown-toggle"
                                      data-toggle="dropdown"
                                      aria-haspopup="true" aria-expanded="false">
                                <span
                                  className="d-none d-md-inline">{locales_es.announcePatient}</span>
                              </button>
                              <div class="dropdown-menu dropdown-menu-right" x-placement="bottom-end">
                                {this.state.consultingRoomOptions && this.state.consultingRoomOptions.length ?

                                  this.state.consultingRoomOptions.map(room => <button
                                    onClick={() => this.onClickAppointmentBell(slot.appointment_id, room.id)}
                                    className="dropdown-item"
                                    type="button">{room.name}</button>)
                                  :
                                  <button className="dropdown-item" type="button">No se encontraron consultorios. Consulte
                                    al administrador</button>
                                }
                              </div>
                            </div>
                          : null}
                        <StatusDropdown appointmentId={slot.appointment_id}
                                        showTitle={true}
                                        showMainModal={this.props.showMainModal}
                                        style={{
                                          paddingLeft: '11px',
                                          float: 'right',
                                        }}
                        />

                        {this.props.medic && this.props.medic.enable_before_payment &&
                          <PaymentStatusDropdown appointmentId={slot.appointment_id}
                                                 showTitle={true}
                                                 showMainModal={this.props.showMainModal}
                                                 style={{
                                                   paddingLeft: '11px',
                                                   float: 'right',
                                                 }}
                          />
                        }
                      </>
                    }
                    {slot.startStr && slot.endStr ?
                      <>
                        <span dangerouslySetInnerHTML={
                          {
                            __html: slot && this.dateTimeService.parseEventDate(slot.startStr)
                          }
                        }/>&nbsp;
                        {
                          slot && this.dateTimeService.parseEventTime(slot.startStr, 'full-string')
                        }
                        &nbsp;-&nbsp;
                        {
                          slot && this.dateTimeService.parseEventTime(slot.endStr, 'full-string')
                        }
                      </>
                      : overbookingModal ?
                        <Form
                          styles={"d-block"}
                          inputs={overbookingModalDatesInput}
                          handleChange={this.handleFormChange}
                          handleDateChange={this.handleDateChange}
                        />
                        : null}
                  </div>
                  <div className="kt-section__desc">

                    {slot.consultation_price || slot.before_payment_amount ?
                      <div className="mt-5 mb-5">
                        {slot.consultation_price ?
                          <p><span
                            className="font-weight-bold">Precio del Turno:</span> {slot.consultation_price !== null ? slot.currency_id || locales_es.currency_sign : ''} {slot.consultation_price}
                          </p>
                          : null}
                        {slot.before_payment_amount ?
                          <p><span
                            className="font-weight-bold">Precio a abonar en la plataforma:</span> {slot.consultation_price !== null ? slot.currency_id || locales_es.currency_sign : ''} {slot.before_payment_amount}
                          </p>
                          : null}
                      </div>
                      : null}

                    {this.state.appointmentTypes.length ?
                      <Form
                        styles={"d-inline-block col-md-6" + (!slot.appointment_id && prices && prices.length && !overbookingModal ? " float-left" : " pl-0")}
                        inputs={inputs}
                        handleChange={this.handleFormChange}
                      />
                      : null
                    }

                    {!slot.appointment_id && prices && prices.length && !overbookingModal ?
                      <Form
                        styles="d-inline-block col-md-6"
                        inputs={priceInputs}
                        handleChange={this.handleFormChange}
                      />
                      : null
                    }
                    <div
                      className="kt-quick-search kt-quick-search--offcanvas kt-quick-search--has-result"
                      id="kt_quick_search_offcanvas">
                      {patient ?
                        slot.info ? null :
                          <button onClick={() => this.removePatient()} type="button"
                                  className="btn btn-elevate btn-circle btn-icon mr-2 float-right">
                            <i className="flaticon-close"/></button>
                        :
                        <>
                          <p><span>{locales_es.patient}</span></p>
                          <form onSubmit={(e) => this.onSearchSubmit(e)}
                                className="kt-input-icon kt-input-icon--right"
                                id="searchForm">
                            <input className="form-control"
                                   type="search"
                                   onChange={(e) => this.onSearchSubmit(e)}
                                   placeholder={locales_es.searchByPatientsNameOrLastname}/>
                            {query &&
                              <span onClick={(e) => this.onSearchClear(e)}
                                    className="kt-input-icon__icon kt-input-icon__icon--right cursor-pointer"
                                    style={{right: 38}}>
                                                            <span><i className="la la-remove"/></span>
                                                        </span>
                            }
                            <span onClick={(e) => this.onSearchSubmit(e)}
                                  className="kt-input-icon__icon kt-input-icon__icon--right">
                                                        <span><i className="la la-search"/></span>
                                                    </span>
                          </form>
                        </>
                      }
                      <div className="kt-quick-search__wrapper">
                        <div className="kt-quick-search__result">
                          {patient ?
                            <>
                              <a className="row" onClick={(e) => {
                                e && e.preventDefault();
                                this.onClickPatientCard(patient);
                              }} target="_blank"
                                 rel="noopener noreferrer">
                                <div className="kt-portlet kt-portlet--height-fluid pointer">
                                  <div className="kt-widget kt-widget--general-2">
                                    <div className="kt-portlet__body kt-portlet__body--fit">
                                      <div className="kt-widget__top p-0">
                                        <div className="kt-portlet__body kt-portlet__body--fit">
                                          <div className="kt-widget__top">
                                            <div className="kt-media kt-media--lg kt-media--circle">
                                              <img
                                                src={patient.full_profile_image}
                                                alt=""/>
                                            </div>
                                            <div
                                              className="kt-widget__wrapper">
                                              <div
                                                className="kt-widget__label"><span
                                                className="kt-widget__title">{patient.name} {patient.lastname}</span>
                                                <span
                                                  className="kt-widget__desc">{patient.identification}</span>
                                                {patient.user && patient.user.email && !this.helpers.isFake(patient.user.email) ?
                                                  <a href={`mailto:${patient.user.email}`}
                                                     className="kt-widget__desc"><i
                                                    className="flaticon2-send kt-font-success"/> {patient.user.email}
                                                  </a>
                                                  : locales_es.hasNotEmail
                                                }

                                                {this.viewHelpers.userDataHasData(patient, 'cellphone') &&
                                                  <>
                                                    <a
                                                      href={`tel:${this.viewHelpers.userDataHasData(patient, 'cellphone')}`}
                                                      className="kt-widget__desc"><i
                                                      className="fa fa-phone-square"/> {this.viewHelpers.userDataHasData(patient, 'cellphone')}
                                                    </a>
                                                  </>
                                                }
                                                {patient.notes && <span
                                                  className="kt-widget__desc"><strong>{`${locales_es.notes}: `}</strong>{patient.notes}</span>}

                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </a>
                              {slot && slot.appointment_id && this.viewHelpers.userDataHasData(patient, 'cellphone') &&
                                <p>
                                  <span className="font-weight-bold">Enviar recordatorio de WhatsApp</span>
                                  <span className="pl-2">
                                    <ContactButtons patient={patient}
                                                    text={`Hola, te escribo para recordarte el turno para el ${this.dateTimeService.parseEventDate(slot.startStr, false, 'full-string')} - ${this.dateTimeService.parseEventTime(slot.startStr, 'full-string')} con ${slot.medic.prefix_name ?? locales_es.medicPrefix} ${slot.medic.name} ${slot.medic.lastname}`}
                                                    buttons={['wa']}/>
                                  </span>
                                </p>
                              }
                              {slot.info ?
                                <>
                                  {slot.owner_str &&
                                    <>
                                      <strong
                                        className="col-form-label">{locales_es.whoIsTheAppointmentPatient}</strong>
                                      <br/>
                                      <label
                                        className="col-form-label">{slot.owner_str}</label>
                                    </>
                                  }
                                  {slot.schedules_reminders && slot.schedules_reminders.length ?
                                    <Schedules data={slot.schedules_reminders}/> : null
                                  }
                                </>
                                :
                                <>
                                  <div
                                    className="kt-separator kt-separator--border-dashed kt-separator--space-lg"/>

                                  <div className="form-group row">
                                    <label
                                      className="col-3 col-form-label">{locales_es.whoIsTheAppointmentPatient}</label>
                                    <div className="col-9">
                                      <div className="kt-radio-list">
                                        <label className="kt-radio">
                                          <input type="radio" name="radio3"
                                                 value="me"
                                                 onChange={(e) => this.onValueChange(e)}
                                                 checked={this.state.patientDestination === 'me'}/> {locales_es.theAppointmentPatientIsForThisPatient}
                                          <span></span>
                                        </label>
                                        <label className="kt-radio">
                                          <input type="radio" name="radio3"
                                                 value="someone"
                                                 onChange={(e) => this.onValueChange(e)}
                                                 checked={this.state.patientDestination === 'someone'}/> {locales_es.theAppointmentPatientIsSomeoneelse}
                                          <span></span>
                                        </label>
                                        {this.state.patientDestination === 'someone' &&
                                          <input className="form-control"
                                                 type="text"
                                                 value={this.state.owner_str}
                                                 onChange={(ev) => this.handleChange(ev)}
                                                 placeholder={locales_es.patientFullName}
                                                 disabled={this.state.patientDestination === 'me'}/>
                                        }
                                      </div>
                                    </div>
                                  </div>
                                </>
                              }

                              {(slot.type_id === APPOINTMENT_VIRTUAL_TYPE || slot.type_id === APPOINTMENT_MIXED_TYPE) &&
                                (slot.external_videocall_url || slot.videocall_hash) &&
                                <div>
                                  <a href={this.getVideocallUrl(slot)}
                                     type="button"
                                     target="_blank"
                                     title={locales_es.enterVideocall}
                                     className={'btn btn-elevate btn-circle btn-icon m-1 ' +
                                       (slot.external_videocall_url ? ' btn-focus' : 'btn-primary')}>
                                    <i className="flaticon-laptop"/>
                                  </a>
                                  <a href={this.getVideocallUrl(slot)}
                                     type="button"
                                     target="_blank"
                                     title={locales_es.enterVideocall}>
                                    {slot.external_videocall_url ? locales_es.enterExternalVideocall : locales_es.enterVideocall}
                                  </a>

                                  <Form
                                    styles="kt-form"
                                    inputs={videocallForm}
                                    handleChange={this.handleFormChange}
                                  />
                                </div>
                              }
                            </>
                            : patients === null ?
                              <div className="row">
                                <div className="col text-center">
                                  <Spinner/>
                                </div>
                              </div>
                              : patients && patients.length ?
                                <>
                                  {otherPatientsSearch &&
                                    <div class="text-center"><h6 class="m-4">Otros pacientes del centro...</h6></div>
                                  }
                                  <div className="row">
                                    {patients.map(patient => {
                                      return (
                                        <div key={patient.id}
                                             onClick={() => this.selectPatient(patient)}
                                             className="w-100 kt-portlet kt-portlet--height-fluid pointer">
                                          <div
                                            className="kt-widget kt-widget--general-2">
                                            <div
                                              className="kt-portlet__body kt-portlet__body--fit">
                                              <div
                                                className="kt-widget__top p-0">
                                                <div
                                                  className="kt-portlet__body kt-portlet__body--fit">
                                                  <div
                                                    className="kt-widget__top">
                                                    <div
                                                      className="kt-media kt-media--lg kt-media--circle">
                                                      <img
                                                        src={patient.full_profile_image}
                                                        alt=""/>
                                                    </div>
                                                    <div
                                                      className="kt-widget__wrapper">
                                                      <div
                                                        className="kt-widget__label"><span
                                                        className="kt-widget__title">{patient.name} {patient.lastname}</span><span
                                                        className="kt-widget__desc">{patient.identification}</span>
                                                        <span
                                                          className="kt-widget__desc">{patient.user && !this.helpers.isFake(patient.user?.email) ? patient.user?.email : locales_es.hasNotEmail}</span>
                                                      </div>
                                                    </div>
                                                  </div>
                                                  {patient.notes && <div className="text-center">
                                                    <strong>{`${locales_es.notes}: `}</strong>{patient.notes}</div>}
                                                  {this.auth.getLocalUserType() === USER_TYPE_SECRETARY && patient.medic ?
                                                    <div class="text-center mb-3">
                                                      <strong>{locales_es.attendedBy}:</strong> {locales_es.medicPrefix} {patient.medic.name} {patient.medic.lastname}
                                                    </div>
                                                    : null}
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      )
                                    })}
                                  </div>
                                  {this.renderAddPatientButton(overbookingModal)}
                                </>
                                : patients === undefined ?
                                  <div className="row mt-3">
                                    <div className="m-auto">
                                      <div className="alert">
                                        <div
                                          className="alert-text">{locales_es.searchAPatientToAssignAppointment}...
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  :
                                  <>
                                    <div className="row mt-3">
                                      <div className="m-auto">
                                        <div className="alert alert-dark"
                                             role="alert">
                                          <div
                                            className="alert-text">{locales_es.noPatientsFound}</div>
                                        </div>
                                      </div>
                                    </div>
                                    {this.renderAddPatientButton(overbookingModal)}
                                  </>
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Modal>
              :
              <>
                <a onClick={(e) => {
                  e.preventDefault();
                  this.showOverbookingModal();
                }}
                   className="btn btn-brand btn-sm btn-bold btn-upper text-white mb-3 cursor-pointer">
                  <i className="fa fa-plus"/> {locales_es.addOverbooking}
                </a>
                <FullCalendar
                  ref={this.calendarComponentRef}
                  // eventClassNames={'medicloud-remove-cursor-icon'}
                  plugins={[dayGridPlugin, timeGridPlugin]}
                  initialView={this.state.initialView}
                  headerToolbar={{
                    left: 'prev,next today',
                    center: 'title',
                    right: 'dayGridMonth,timeGridWeek,timeGridDay'
                  }}
                  timeZone={this.state.timezone ?? DEFAULT_TIME_ZONE}
                  // slotMinTime={FC_SLOT_MIN_TIME}
                  // slotMaxTime={FC_SLOT_MAX_TIME}
                  slotMinTime={this.state.slotMinTime}
                  slotMaxTime={this.state.slotMaxTime}
                  slotDuration={this.state.slotDuration}
                  eventClick={(e) => {
                    this.onEventClick(e.event.extendedProps)
                  }}
                  // titleFormat={(e) => console.log(e)}
                  locale={fcLocale}
                  allDaySlot={false}
                  events={
                    (fetchInfo, successCallback, failureCallback) => {
                      if (this.retryCount < this.maxRetries) {
                        this.getCalendarData(fetchInfo, successCallback, failureCallback)
                      } else {
                        console.info('Max retries reached. Stopping further attempts.');
                      }
                    }
                  }
                  initialDate={this.state.initialDate}
                  viewDidMount={(e) => {
                    if (e && e.view && e.view.type) {
                      this.calendarView.set(e.view.type);
                    }
                  }}
                  datesSet={handleDatesSet}
                  eventDidMount={(info) => {
                    const element = info.el;
                    const patient = element.getElementsByClassName('fc-event-title')[0].innerHTML;
                    const classList = element.className.split(' ');
                    const statusClassname = classList.filter(className => className.startsWith('medicloud-event-status-'))[0];

                    const popover = function () {
                      // Crear el popover
                      if (statusClassname) {
                        const status = statusClassname.replace('medicloud-event-status-', '');
                        var popover = window.$(`<div class="popover" role="tooltip"><div class="arrow"></div><h3 class="popover-header">${patient}</h3><div class="popover-body">${locales_es.appointmentStatus}: <span class="kt-badge ${STATUS_COLORS.appointmentBadges[status]} kt-badge--inline">${locales_es[statusClassname]}</span></div></div>`);
                        window.$('body').append(popover);

                        // Mostrar el popover
                        popover.css({
                          position: 'absolute',
                          top: window.$(this).offset().top + window.$(this).outerHeight(),
                          left: window.$(this).offset().left
                        }).show();
                      }
                    }

                    const hidePopover = function () {
                      // Ocultar el popover cuando el mouse se va
                      // window.$('.popover').remove();
                      window.hidePopovers && window.hidePopovers();
                    }

                    window.$(element).hover(popover, hidePopover);
                    window.$(element).click(hidePopover);
                  }}
                />
                <br/>
                <h6>{locales_es.calendarConfiguration}:</h6>
                <TimePicker ref={this.timePickerRef} onSlotMinTime={(time) => {
                  this.setState({
                    slotMinTime: time,
                  })
                }} onSlotMaxTime={(time) => {
                  this.setState({
                    slotMaxTime: time,
                  })
                }} onSlotDurationChange={(time) => {
                  this.setState({
                    slotDuration: time,
                  })
                }} enabledFields={['startTime', 'endTime', 'slotDuration']}/>
                <div class="text-center mt-3">
                  <button className="btn btn-link mb-3" onClick={this.clearTimes}>{locales_es.cleanFilters}</button>
                </div>
                <br/>
                <h6>{locales_es.reference}:</h6>
                <div style={{
                  backgroundColor: '#ddd',
                  padding: 10,
                  color: '#333',
                }}>
                  <img src={VideoCallIcon}/> {locales_es.videocalAppointment}
                </div>
              </>
        }
      </>
    )
  }
}
